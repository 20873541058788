.contact-container {
  .contact-title-container {
    background-image: url("../../images/faqs-title-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px 0px 70px 0px;
    .title {
      display: flex;
      justify-content: center;
      font-size: 30px;
      font-weight: 800;
      color: white;
    }

    .desc {
      text-transform: capitalize;
      font-weight: bold;
      color: grey;
      font-size: 14px;
      text-align: center;
    }
  }

  .contact-desc-container {
    background-image: url("../../images/faqs-desc-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;

    .contact-desc-tittle {
      font-size: 30px;
      font-weight: 800;
      color: #4da9ca;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
    }
    .ant-card {
      box-shadow: 3px 3px 6px 0px #898989;

      Button {
        background-color: #4da9ca;
        border-radius: 50px;
        width: 100%;
        font-weight: 700;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
    }
    .card-title {
      font-size: 16px;
      font-weight: 700;
      color: #4da9ca;
    }

    .card-desc {
      font-weight: 600;
      color: #b0b0b0;
      margin-bottom: 20px;
    }
  }
}
