.privacy-policy-container {
    background-image: url("../../images/aboutus-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
    .privacy-policy-title {
      margin-top: 100px;
  
      div {
        font-size: 26px;
        font-weight: 800;
        color: #4da9ca;
      }
      .desc {
        padding-bottom: 100px;
      }
  
      p {
        text-transform: capitalize;
        font-weight: bold;
        color: grey;
        font-size: 14px;
        text-align: justify;
      }
  
      .fastest-loan-container {
        margin-top: 100px;
        margin-bottom: 100px;
  
        .faster-loan-wrapper {
          display: flex;
          justify-content: center;
        }
  
        .min-image {
          img {
            position: absolute;
            top: -23%;
            left: 33%;
          }
        }
        .ant-card {
          box-shadow: 3px 3px 6px 0px #898989;
          width: 300px;
          height: 300px;
        }
  
        .ant-card:hover {
          transform: translateY(8px);
          box-shadow: 2px 2px 2px #999;
        }
  
        .title {
          margin-top: 20px;
          color: #898989;
          font-size: 16px;
          margin-bottom: 20px;
        }
  
        .desc {
          color: #898989;
          font-size: 14px;
          text-align: center;
          font-weight: 400;
          padding: 0;
          margin-bottom: 20px;
        }
  
        .desc2 {
          font-size: 16px;
        }
      }
    }
  
    .image-divider {
      margin-bottom: 150px;
    }
  }
  