.navbar-container {
  padding: 0 10px 0 10px;
  background-color: white;
  .navbar-logo {
    padding: 20px 10px 20px 10px;
    margin-left: 80px;
  }

  .navbar-menu-container {
    height: 100%;
    margin-bottom: 20px;
    .navbar-menu {
      font-size: 1.1rem;
      font-weight: 700;
      color: #2596be;
      width: 80%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .language {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .navbar-menu:hover {
      color: white;
      background-color: #2596be;
    }
  }

  .navbar-menu-small {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
  }
}

@media only screen and (min-width: 300px) and (max-width: 575px) {
  .navbar-container {
    background-color: #ddf1f7;
    .navbar-logo {
      padding: 20px 10px 20px 10px;
      margin: 0;
    }

    .navbar-menu-container {
      height: 100%;
      margin-bottom: 20px;
      .navbar-menu {
        font-size: 1.1rem;
        font-weight: 700;
        color: #2596be;
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .language {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .navbar-menu:hover {
        color: white;
      }
    }

    .navbar-menu-small {
      display: flex;
      justify-content: end;
      align-items: center;
      height: 100%;
    }
  }
}
