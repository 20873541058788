.faqs-container {
  .faqs-title-container {
    background-image: url("../../images/faqs-title-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    padding: 30px 0px 70px 0px;
    .title {
      font-size: 30px;
      font-weight: 800;
      color: white;
    }

    .desc {
      text-transform: capitalize;
      font-weight: bold;
      color: grey;
      font-size: 14px;
      text-align: center;
    }
  }

  .faqs-desc-container {
    background-image: url("../../images/faqs-desc-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;

    .faqs-desc-tittle {
      font-size: 30px;
      font-weight: 800;
      color: #4da9ca;
      margin-bottom: 40px;
    }
    .faq-menu-type {
      font-size: 15px;
      font-weight: 600;
      line-height: 40px;
      cursor: pointer;
    }

    .selectedLine {
      background: linear-gradient(91.23deg, #ffffff 0%, #4da9ca 100%);
      width: 0;
      height: 3px;
      bottom: 0;
      opacity: 0;
      transition: all 0.5s;
      margin-bottom: 20px;
    }

    .selectedLine.active {
      color: #e5b35c;
      width: 100%;
      opacity: 1;
    }

    .faq-menu-type.active {
      color: #4da9ca;
    }

    .accordion {
      color: white;
      font-weight: 600 !important;

      --cui-accordion-bg: !important;
      --cui-accordion-active-bg: #4da9ca;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      --cui-accordion-border-color: none;
      --cui-accordion-active-color: none;
      --cui-accordion-color: black;
      --cui-accordion-btn-color: black;
    }
    .accordion-item {
      margin-bottom: 20px;
    }

    .accordion-body {
      background-color: #f2f2f2;
      border-radius: 10px;
    }
    .accordion-button:focus {
      outline: 0;
      box-shadow: none;
    }
    .accordion-header {
      border-radius: 10px;
      background-color: #4da9ca !important;

      .accordion-button {
        padding: 15px;
        border-radius: 10px;
        font-weight: 700;
      }
      div {
        font-weight: 600 !important;
        font-size: 16px;
      }
    }
  }
}
